const prepareSlug = (slug: string, prefixRedirect: string): string => {
    if (slug.slice(0, 2) === prefixRedirect) {
        return slug.slice(2);
    }
    return '';
};

export const redirect = {
    prepareSlug,
};
