import React, { FC } from 'react';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { MainInfoBlocks } from '@/widgets/main-info-blocks';

import { TSupportQuestion } from '@/entities/faq';
import { TDynamicPage } from '@/entities/pages';
import { TProductCard } from '@/entities/product';

import { HeadMeta } from '@/shared/ui/head-meta';
import { LayoutDefault } from '@/shared/ui/layout/default';

type TProps = {
    faq: TSupportQuestion[];
    page: TDynamicPage;
    products: TProductCard[];
};

export const DynamicPage: FC<TProps> = ({ page, products, faq }) => {
    const metadata = {
        description: page.meta_description,
        keywords: page.meta_keywords,
        title: page.meta_title,
    };
    return (
        <LayoutDefault header={<Header />} footer={<Footer />}>
            <HeadMeta metadata={metadata} />
            <MainInfoBlocks products={products} faq={faq} page={page} />
        </LayoutDefault>
    );
};
