import { NextPage, NextPageContext } from 'next';

import { loadDefaultSettingsSite, wrapperReduxToolkit } from '@/store';

import { DynamicPage } from '@/pages/dynamic';
import { ErrorPage } from '@/pages/error';

import { getMainPageFaqQuestions, TSupportQuestion } from '@/entities/faq';
import { getDynamicPageBySlug, TDynamicPage } from '@/entities/pages';
import { getProductCards, TProductCard } from '@/entities/product';
import { findRedirect } from '@/entities/redirects';

import { NOT_FOUND_PAGE, STATUS_CODE_OK, TLangSite } from '@/shared/constants/settings';

type TProps = {
    faq: TSupportQuestion[];
    page: TDynamicPage;
    products: TProductCard[];
    statusCode: number;
};
const Page: NextPage<TProps> = ({ faq, page, products, statusCode }) => {
    if (statusCode !== STATUS_CODE_OK) {
        return <ErrorPage statusCode={statusCode} />;
    }
    return <DynamicPage faq={faq} page={page} products={products} />;
};

Page.getInitialProps = wrapperReduxToolkit.getInitialPageProps(
    ({ dispatch, getState }) =>
        async (context: NextPageContext) => {
            // Загрузка стора при серверном и клиенском рединге, общих настроек
            await loadDefaultSettingsSite(getState, dispatch, context.locale as TLangSite);

            const query = context.query;
            let slug = query.slug;

            if ((query.slug as string[]).length > 0) {
                if (query.slug && query.slug[0].includes('.js')) {
                    return { statusCode: NOT_FOUND_PAGE };
                }

                slug = (query.slug as string[]).join('/');
            }

            const responsePage = await getDynamicPageBySlug(slug as string);

            if (responsePage.statusCode === STATUS_CODE_OK) {
                const response = await Promise.all([getMainPageFaqQuestions(), getProductCards()]);
                return {
                    faq: response[0].data,
                    page: responsePage.data as TDynamicPage,
                    products: response[1],
                    statusCode: STATUS_CODE_OK,
                };
            }

            const redirect = await findRedirect(slug as string);

            if (redirect.target && context.res) {
                context.res.writeHead(301, {
                    location: redirect.target,
                });
                context.res.end();
            }

            if (context.res) {
                context.res.statusCode = NOT_FOUND_PAGE;
            }

            return { statusCode: NOT_FOUND_PAGE };
        },
);

export default Page;
