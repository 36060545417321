import { AxiosError } from 'axios';

import { TReadParamsProperties, ResourceService } from '@/shared/api';
import { axiosSiteClient } from '@/shared/api/site';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { TRedirect } from '../types';

export const RedirectService = ResourceService('redirects', axiosSiteClient);

export const getRedirects = async (fromRoute: string): Promise<TRedirect | null> => {
    const response = await RedirectService.read<{ data: TRedirect[] }, TReadParamsProperties>({
        params: {
            source: fromRoute,
            itemsPerPage: 30,
            page: 1,
        },
    });

    return response.data[0] ?? null;
};

export const findRedirect = async (slug: string) => {
    let statusCode = STATUS_CODE_OK;

    try {
        const foundRedirectRoute = await getRedirects(slug);

        if (foundRedirectRoute && foundRedirectRoute.target) {
            let target = '';
            if (foundRedirectRoute.target.startsWith('http')) {
                target = foundRedirectRoute.target;
            } else {
                target =
                    foundRedirectRoute.target[0] === '/' ? foundRedirectRoute.target : `/${foundRedirectRoute.target}`;
            }

            const targetEncode = `${/[а-яА-ЯЁё\s]/i.test(target) ? encodeURIComponent(target) : target}`;

            return {
                target: targetEncode,
                statusCode,
            };
        }
    } catch (e) {
        const error = e as AxiosError;
        customCaptureException(e, 'findRedirect');

        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return { statusCode };
};
